import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import GridTwo from '~components/common/GridTwo';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import ImageVideo from '~components/common/ImageVideo';
import Divider from '~components/common/Divider';

export default function Page({ location }) {
  return (
    <Layout location={location} title="Die Entspannungshelden stellen sich vor" desc="Hier erfährst du, wie wir es geschafft haben entspannter zu werden" active="Über uns">
      <PageHeader title="Über uns" breadcrumb={[{ title: 'Über uns', link: '/ueber-uns/', current: true }]} />

      <Container>
        <Divider />
        <div className="my-8 text-center">
          <P className="mb-3">Unsere Geschichte</P>
          <H2 className="font-display">So haben wir uns entspannt</H2>
        </div>
        <GridTwo itemsCenter>
          <div>
            <StaticImage src="../media/johannes-daumen.jpg" alt="Johannes mit Daumen hoch" className="rounded-xl" />
          </div>
          <div className="max-w-lg">
            <P>
              Wir sind davon überzeugt, dass sich Entspannungsverfahren zusammen mit der heutigen Digitalisierung perfekt ergänzen und sich daraus eine wunderbare Kombination ergibt. Obwohl beide aus unterschiedlichen Berufszweigen kommen,
              überschneidet sich die Expertise. So entstanden die Entspannungshelden. Unser Ziel ist es, so vielen Menschen wie möglich auf die einfachste Weise den Zugang zu qualitativ hochwertigen Entspannungsverfahren zu ermöglichen.
            </P>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}
